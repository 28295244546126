/* You can add global styles to this file, and also import other style files */
// @use 'app/theme/theme.scss';

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// regular style toast
@import 'ngx-toastr/toastr';

@font-face {
  font-family: 'IbmPlexSans';
  src: url('assets/fonts/IBMPlexSans-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IbmPlexSans';
  src: url('assets/fonts/IBMPlexSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IbmPlexSans';
  src: url('assets/fonts/IBMPlexSans-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@import "./app/theme/form/form";

:root {
  --accent-color: #ff4733;
}

body {
  color: #333;
  background-color: #ffffff;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 14px;
  font-family: 'Arial';
}

a {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 10px;
}

input {
  box-sizing: border-box;
}

textarea {
  box-sizing: border-box;
  display: block;
  font-family: 'Arial';
  resize: none;
}

.btn {
  background-color: var(--accent-color) !important;
  border: none;
  border-radius: 6px;
  color: #ffffff !important;
  cursor: pointer;
  padding: 15px 40px;
  text-align: center;
  white-space: normal;
}

.btn-secondary {
  background-color: #ff5500 !important;
  color: #ffffff !important;
}

.text-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase !important;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.full-width {
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

#main-form__section #main-form__container {
  width: 40%;
}

#spinner-container mat-spinner {
  max-height: 2rem;
}

.error-message {
  color: red;
}
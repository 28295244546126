#main-form__section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 128px);

  #main-form__container {
    width: fit-content;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 1px lightgray;
    border-radius: 10px;
    position: relative;

    #main-form__title {
      margin: 2rem 0;
      margin-top: 0;
    }
  }

  .error-message {
    color: red;
    position: absolute;
    bottom: 0;
  }
}